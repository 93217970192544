import * as types from './types';

export const getTariffsRequest = () => ({
  type: types.GET_TARIFFS_REQUEST,
});

export const getTariffsError = (error) => ({
  type: types.GET_TARIFFS_ERROR,
  error,
});

export const getTariffsSuccess = (tariffs) => ({
  type: types.GET_TARIFFS_SUCCESS,
  tariffs,
});

export const setSelectedTariffLabel = (label) => ({
  type: types.SET_SELECTED_TARIFF_LABEL,
  label,
});

export const removeSelectedMobileProduct = (productId) => ({
  type: types.REMOVE_SELECTED_MOBILE_PRODUCT,
  productId,
});

export const addMobileProduct = () => ({
  type: types.ADD_MOBILE_PRODUCT,
});

export const putMobileSelectionRequest = () => ({
  type: types.PUT_MOBILE_SELECTION_REQUEST,
});

export const putMobileSelectionSuccess = () => ({
  type: types.PUT_MOBILE_SELECTION_SUCCESS,
});

export const openSpendCapDialog = (productId, spendCapSelected) => ({
  type: types.OPEN_SPEND_CAP_DIALOG,
  productId,
  spendCapSelected,
});

export const updateSpendCapSelected = (spendCapSelected) => ({
  type: types.UPDATE_SPEND_CAP_SELECTED,
  spendCapSelected,
});

export const submitSpendCapDialog = () => ({
  type: types.SUBMIT_SPEND_CAP_DIALOG,
});

export const closeSpendCapDialog = () => ({
  type: types.CLOSE_SPEND_CAP_DIALOG,
});

export const openNumberOptionsDialog = (productId, values) => ({
  type: types.OPEN_NUMBER_OPTIONS_DIALOG,
  productId,
  values,
});

export const updateNumberOptionsDialogField = (field, value) => ({
  type: types.UPDATE_NUMBER_OPTIONS_DIALOG_FIELD,
  field,
  value,
});

export const updateNumberOptionsDialogErrors = (errors) => ({
  type: types.UPDATE_NUMBER_OPTIONS_DIALOG_ERRORS,
  errors,
});

export const closeNumberOptionsDialog = () => ({
  type: types.CLOSE_NUMBER_OPTIONS_DIALOG,
});

export const validateAndSubmitNumberOptionsDialog = () => ({
  type: types.VALIDATE_AND_SUBMIT_NUMBER_OPTIONS_DIALOG,
});

export const submitNumberOptionsDialog = (productId, values) => ({
  type: types.SUBMIT_NUMBER_OPTIONS_DIALOG,
  productId,
  values,
});

export const preselectSuccess = (preselectState) => ({
  type: types.PRESELECT_SUCCESS,
  preselectState,
});

import { STORE_NAME } from './constants';

export const getSpendCapDialogState = (state) =>
  state.getIn([STORE_NAME, 'spendCapDialog']).toJS();

export const getNumberOptionsDialogState = (state) =>
  state.getIn([STORE_NAME, 'numberOptionsDialog']).toJS();

export const getSelectedMobileProducts = (state) =>
  state.getIn([STORE_NAME, 'selectedProducts']).toJS();

export const getMobileTariffs = (state) =>
  state.getIn([STORE_NAME, 'tariffs']).toJS();

export const getSelectedTariff = (state) => {
  const selectedTariffLabel = state.getIn([STORE_NAME, 'selectedTariffLabel']);
  return state
    .getIn([STORE_NAME, 'tariffs', 'nodes'])
    ?.toJS()
    ?.find((t) => t.label === selectedTariffLabel);
};

export const getExistingMobileNumber = (state) => {
  const hasExistingNumber = getSelectedMobileProducts(state).find(
    (s) => s.options.values.existingNumber
  );

  if (hasExistingNumber) {
    return hasExistingNumber.options.values.existingNumber;
  }

  return null;
};

export const getSelectedOptions = (state) => {
  const tariff = getSelectedTariff(state);
  const selectedProducts = getSelectedMobileProducts(state);

  return {
    tariff: tariff.name,
    count_services: selectedProducts.length,
    count_mobile_numbers_to_keep: selectedProducts.filter(
      (product) => product.options.values.keepMyNumber
    ).length,
    count_existing_services_to_cancel: selectedProducts.filter(
      (product) => product.options.values.cancelOldContract
    ).length,
    count_services_for_budget_control: selectedProducts.filter(
      (product) => product.options.values.budgetControl
    ).length,
  };
};
